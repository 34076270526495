import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function About({data}) {
    return (
        <>
            <Page title={"Om oss"}>
                <div className="py-12 bg-white">
                    <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
                        <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                            <div className="md:7/12 lg:w-6/12">
                                <h1 className="text-2xl text-gray-900 font-bold md:text-4xl">Om oss</h1>
                                <p className="mt-6 text-gray-600">Välkommen till skolapparna.se och så roligt att du har hittat hit. Vårt mål är att samla alla skolappar och hjälpmedel för elever och lärare på ett och samma ställe.</p>
                                <p className="mt-4 text-gray-600">Skolapparna grundades i slutet av vårterminen under 2022 eftersom vi upplevde att det inte fanns någon bra webbplats där elever och lärare kunde hitta passande appar för undervisningen och lärandet.</p>
                                <p className="mt-4 text-gray-600">Vi vill vara med och fortsätta digitalisera lärandet genom att vara samlingsplatsen för skolappar och hjälpmedel för elever och lärare.</p>                                 
                            </div>
                            <div className="md:5/12 lg:w-5/12">
                            <GatsbyImage image={data.homeTop.childImageSharp.gatsbyImageData} className={"rounded-2xl"}/>
                            </div>
                        </div>
                    </div>
                </div>
               
            </Page>
        </>
    );
}


export const pageQuery = graphql`
  query {
    homeTop: file(relativePath: { eq: "skolrum.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
